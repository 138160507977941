// AddCourse Module
#wrapper {
    overflow-x: hidden;
}

.sidebar-wrapper {
    min-height: 98vh;
    // margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
    .list-group {
        width: 15rem;
    }
    .sidebar-heading {
        padding: 0.875rem 1.25rem;
        font-size: 1.2rem;
    }
}

.page-content-wrapper {
    min-width: 100vw;
}

.subjects {
    a:hover {
        text-decoration: none;
    }
}

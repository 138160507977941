.crash-card {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    // border-radius: 8px;
    transition: all 0.2s ease-in-out;
    border: 1px solid #075e5462;
    border-bottom: 0px solid #075e5462;
    cursor: pointer;
    border-bottom: 1px solid #075e5462;

&:last-child{

}
    &:hover {
        // outline: 3px solid #075e54;
        // border-bottom: 1px solid #075e54;
        background-color: #075e5425;
    }

    .number {
        padding: 7px 14px;
        line-height: 22px;
        font-size: 22px;
        font-weight: bolder;
        background-color: #075e54;
        color: #fff;
        border-radius: 100px;
    }
}

.font-11 {
    font-size: 11px;
}
.font-12 {
    font-size: 12px;
}

.font-16 {
    font-size: 16px;
}
.font-14 {
    font-size: 14px;
}
.font-17 {
    font-size: 17px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-22 {
    font-size: 22px;
}
.font-24 {
    font-size: 24px;
}
.font-26{
    font-size: 26px;
}
.font-28{
    font-size: 28px;
}

.font-30 {
    font-size: 30px;
}



// font weight
.font-200 {
    font-weight: 200;
}
.font-300 {
    font-weight: 300;
}
.font-400 {
    font-weight: 400;
}
.font-500 {
    font-weight: 500;
}
.font-600 {
    font-weight: 600;
}
.font-700 {
    font-weight: 700;
}
.font-900 {
    font-weight: 900;
}

// line-height
.line-28 {
    line-height: 30px;
}

.text-italic {
    font-style: italic;
}

hr {
    margin: 0;
}

.font-bold {
    font-weight: bold !important;
}

// large fonts
.font-54 {
    font-size: 54px;
    @media screen and (max-width: 768px) {
        font-size: 32px;
    }
}
.font-42 {
    font-size: 42px;
    @media screen and (max-width: 768px) {
        font-size: 32px;
    }
}
@media screen and (max-width: 768px) {
    .font-30{
        font-size: 24px;
    }
}